import {
    Link
} from "react-router-dom";

const Home = () => {
    

    return (
        <div style={{display:'flex',flexDirection:'column'}}>
            <Link to={{
                pathname: "/Carousel",
                state: { id: 76711 }
            }}>Carousel id:76711</Link>  
            <Link to={{
                pathname: "/Carousel",
                state: { id: 67092 }
            }}>Carousel juego super largo id:67092</Link>
             <Link to={{
                pathname: "/Ranking",
                state: { id: 76235 }
            }}>Ranking normal id:76235</Link> 
             <Link to={{
                pathname: "/Ranking",
                state: { id: 76300 }
            }}>Ranking largo 8 opciones id:76300</Link> 
             <Link to={{
                pathname: "/Ranking",
                state: { id: 68007 }
            }}>Ranking largo 10 opciones id:68007</Link> 
            <Link to={{
                pathname: "/IdentifyImage",
                state: { id: 76660 }
            }}>IdentifyImage id:76660</Link> 
             <Link to={{
                pathname: "/IdentifyImage",
                state: { id: 64067 }
            }}>IdentifyImage largo id:64067</Link> 
            <Link to='WordSearch'>WordSearch id:76239</Link> 
            <Link to={{
                pathname: "/GameTextTest",
                state: { id: 76689 }
            }}>GameTextTest id:76689</Link> 
             <Link to={{
                pathname: "/GameTextTest",
                state: { id: 77042 }
            }}>GameTextTest 3 respuestas id:77042</Link> 
             <Link to={{
                pathname: "/GameTextTest",
                state: { id: 77043 }
            }}>GameTextTest 4 respuestas id:77043</Link>  
             <Link to={{
                pathname: "/GameTestImage",
                state: { id: 76751 }
            }}>GameTestImage id:76751</Link>
             <Link to={{
                pathname: "/GameTestImage",
                state: { id: 77052 }
            }}>GameTestImage super largo de 4 respuestas id:77052</Link>
             <Link to={{
                pathname: "/CoupleText",
                state: { id: 76630 }
            }}>CoupleText id:76630</Link> 
             <Link to={{
                pathname: "/CoupleText",
                state: { id: 77053 }
            }}>CoupleText super largo id:77053</Link>  
            <Link to='MatchImages'>MatchImages id:76667</Link> 
            <Link to='Map'>Map id:76749</Link>    
            <Link to='OrderSentence'>OrderSentence id:74181</Link> 
            <Link to={{
                pathname: "/SelectAnswers",
                state: { id: 76737 }
            }}>SelectAnswers id:76737</Link>
            <Link to={{
                pathname: "/SelectAnswers",
                state: { id: 43965 }
            }}>SelectAnswers super largo id:43965</Link>  
            <Link to='WhiteList'>WhiteList id:51977</Link> 
            <Link to={{
                pathname: "/Group",
                state: { id: 76441 }
            }}>Group id:76441</Link>
            <Link to={{
                pathname: "/Group",
                state: { id: 76170 }
            }}>Group super largo id:76170</Link>
             <Link to={{
                pathname: "/GroupText",
                state: { id: 76575 }
            }}>Group Text id:76575</Link>
            <Link to={{
                pathname: "/GroupText",
                state: { id: 77028 }
            }}>Group Text super largo id:77028</Link>
            <Link to='CatchMe'>CatchMe id:76729</Link>
            <Link to={{
                pathname: "/SecretWords",
                state: { id: 75295 }
            }}>SecretWords id:75295</Link>
            <Link to={{
                pathname: "/SecretWords",
                state: { id: 55081 }
            }}>SecretWords super largo id:55081</Link>
        </div>
           
    )
}

export default Home;