import React, {useContext, useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import "./catchMe.scss";

import Layout from "../Layout";
import Button from "../../components/Button";
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";
import {DataContext} from "../../utils/context";

const TheFall = ({height, gotWrong, children}) => {
    const [style, api] = useSpring(() => ({}));

    useEffect(() => {
        if (gotWrong) {
            api.start({
                to: [
                    {
                        y: 0,
                        delay: 300
                    },
                    {
                        y: height,
                        config: {
                            duration: 1000
                        },
                    }
                ]
            })
        } else {
            api.start({
                y: 0,
                immediate: true
            })
        }
    }, [api, style, height, gotWrong])

    return <animated.div style={style}>
        {children}
    </animated.div>
}

const getDir = (width, dir = null) => {
    if (dir === null) {
        dir = randomNum(0, 2) ? 1 : -1;
    } else {
        dir = dir > 0 ? -1 : 1;
    }

    return {
        from: {
            x: dir > 0 ? -200 : width + 200,
            opacity: 1,
            display: 'block'
        },
        to: {
            x: dir > 0 ? width + 200 : -200,
            opacity: 1,
            display: 'block'
        }
    }
}

const Float = ({items, due, setCorrect, setIncorrect, gotWrong, passed}) => {
    const {deviceWidth,deviceHeight} = useContext(DataContext);
    const [options, setOptions] = useState(items);
    const [currentIndex, setCurrentIndex] = useState(items[0]);
    const [completed, setCompleted] = useState(false);
    const [clicked, setClicked] = useState(false);

    const [styles, api] = useSpring(() => ({
        display: 'none'
    }))

    const correction = () => {
        if (currentIndex.correct && !clicked) {
            api.start({
                opacity: 0,
                config: {
                    duration: 500
                },
            })

            setTimeout(() => {
                let temp = removeItemFromArr(currentIndex.a, options);
                if (temp.length > 1) {
                    setCurrentIndex(temp[randomNum(0, temp.length - 1)]);
                } else if (temp.length === 1) {
                    setCurrentIndex(temp[0])
                } else {
                    setCurrentIndex(null)
                }

                setOptions(temp);
                setClicked(true);
            }, 500)
            setCorrect(currentIndex.a);
        } else {
            setIncorrect();
            setClicked(true);
        }
    }

    useEffect(() => {
        let resumeAni = true;
        if (completed) {
            let temp = options;
            setClicked(false);

            if (options.length > 1) {
                temp = removeItemFromArr(currentIndex.a, temp);
                setCurrentIndex(temp[randomNum(0, temp.length - 1)]);
            } else if (options.length === 1) {
                setCurrentIndex(temp[0]);
            } else {
                resumeAni = false;
            }

            resumeAni && !passed && api.start({
                ...getDir(deviceWidth),
                config: {
                    duration: 1000 * due * randomNum(4, 1) + 100
                },
                onRest: () => setCompleted(true)
            })
            setCompleted(false);
        }

        return () => resumeAni = false;
    }, [currentIndex, api, deviceWidth, due, completed, options, passed]);

    useEffect(() => {
        if (gotWrong) {
            api.start({
                pause: true
            })
        } else {
            setClicked(false);
            currentIndex && api.start({
                ...getDir(deviceWidth),
                config: {
                    duration: 1000 * due * randomNum(1, 4) + 200
                },
                onRest: () => setCompleted(true),
                pause: false
            })
        }
    }, [gotWrong, api, due, deviceWidth, currentIndex])

    return <>
        <animated.div
            className={'moving-parts'}
            style={styles}
        >
            {
                currentIndex ?
                <Button
                    value={currentIndex.a}
                    wrong={currentIndex.wrong}
                    onClick={correction}
                    style={{minWidth: 70},deviceWidth > 768 && deviceHeight >520 ? {padding:'15px 25px'}:null}
                    correct={currentIndex.correct}
                />  :
                    <Button
                        value={'nn'}
                    />
            }
        </animated.div>
    </>
}

const FloatContainer = ({questions, correct, incorrect, passed}) => {
    const [data, setData] = useState(questions);
    const {deviceHeight, deviceWidth} = useContext(DataContext);
    const [maxDue, setMaxDue] = useState(4);
    const [floating, setFloating] = useState([]);
    const [gotWrong, setGotWrong] = useState(false);

    const incorrectPress = () => {
        incorrect();
        setGotWrong(true);
        setTimeout(() => {
            setGotWrong(false);
        }, 2000)
    };

    const correctPress = (item) => {
        setData(removeItemFromArr(item, data))
        correct();
    };

    useEffect(() => {
        setMaxDue(Math.ceil(deviceWidth / 200));
    }, [maxDue, deviceWidth]);

    useEffect(() => {
        if (deviceHeight) {
            let temp = [...data];
            let elements = [];
            const rows = Math.round((deviceHeight - 70) / 150)
            let divider = data.length > rows ? data.length / rows : 1;

            for (let i = 0; i < rows - 1; i++) {
                elements.push(temp.splice(0, divider));
            }

            elements.push(temp);
            setFloating(elements)
        }
    }, [data, deviceHeight])

    return <>
        {
            deviceHeight && floating.map((items, i) =>
                <TheFall
                    key={i}
                    height={deviceHeight}
                    gotWrong={gotWrong}
                >
                    <Float
                        i={i}
                        items={items}
                        due={maxDue}
                        setCorrect={correctPress}
                        setIncorrect={incorrectPress}
                        gotWrong={gotWrong}
                        passed={passed}
                    />
                </TheFall>
            )
        }
    </>
}

const CatchMe = () => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [corrects, setCorrects] = useState(null);

    const setGameData = (data) => {
        let temp = [];
        const {corrects, incorrects} = data;

        setCorrects(corrects.length);

        corrects.forEach(value => {
            temp.push({
                a: value,
                correct: true,
                corrected: false,
                wrong: false,
                side: randomNum(0, 2) ? 'left' : 'right',
                speed: randomNum(5, 1)
            })
        });

        incorrects.forEach(value => {
            temp.push({
                a: value,
                correct: false,
                corrected: false,
                wrong: false,
                side: randomNum(0, 2) ? 'left' : 'right',
                speed: randomNum(5, 1)
            })
        });

        setQuestions(shuffleArray(temp).map((item, i) => {
            item.index = i;
            return item;
        }));
    }


    return (
        <Layout
            gameId={76729}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={setGameData}
        >
            <div
                className={'catch-me-main'}
            >
                <FloatContainer
                    questions={questions}
                    correct={() => setProgressSteps(progressSteps + 1)}
                    incorrect={() => setLives(lives - 1)}
                    passed={corrects === progressSteps}
                />
            </div>
        </Layout>
    )
}

export default CatchMe;