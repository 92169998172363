import React, {useState} from "react";

import Layout from "../Layout";
import KeyBoardButtons from "../../components/KeyBoardButtons";
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";

// const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

const SecretWords = (props) => {
    const [questions, setQuestions] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [current, setCurrent] = useState({});
    const [spaces, setSpaces] = useState([]);
    const [options, setOptions] = useState([]);

    const processed = (data) => {
        let item = data[randomNum(0, data.length - 1)];
        let answer = item.a.trim().split('');
        setSpaces(answer.map(item => item === ' ' ? 'space' : ''));
        setOptions(shuffleArray(
            answer.filter(item => item !== ' ')
                .map(char => char.toUpperCase())
                .filter((item, pos, self) => (self.indexOf(item) === pos))
        ));
        setCurrent(item);
    }

    const gameData = (data) => {
        setQuestions(data);
        processed(data);
    };

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.a, arr);
                processed(temp);
            } else {
                setTimeout(() => {
                    processed(temp);
                }, 400);
            }
        }
    };

    const correctTry = () => {
        setProgressSteps(progressSteps + 1)
        let temp = removeItemFromArr(current.a, questions);
        setQuestions(temp);
        next(temp);
    }


    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            gameTitle={current.q}
            remainingLives={lives}
            next={() => next(questions, true)}
            reqGameData={gameData}
        >
            <KeyBoardButtons
                answerSlots={spaces}
                answerOpts={options}
                correctAnswer={current.a}
                incorrectTry={() =>  setLives(lives - 1)}
                goToNextGame={correctTry}
            />
        </Layout>
    )
}

export default SecretWords;