import React, {useContext, useEffect, useState} from "react";
import './showAnswers.scss';
import { useHistory } from 'react-router-dom';

const ShowAnswers = () => {
    
    const history = useHistory();
    const [responses,setResponse] = useState(history.location.state.responses)
    const [type,setType] = useState(history.location.state.gameType)
    const success = 75
    console.log(responses)

    return (
        <div className={'show-answers-container'}>
            {/* <div className={'background'}></div> */}
            <div className={'show-answers-header'}>
                <div style={responses.answersa ? {display:'flex',justifyContent:'flex-end'}:null} className={'left'}>
                    <h3 style={responses.answersa ? {marginRight:20,display:'flex',justifyContent:'flex-end'}:{marginRight:0}}>{responses.answersa ? responses.header.groupa : 'Respuestas'}</h3>
                </div>
                {responses.answersb ? <div className={'right'}>
                    <h3>{responses.header.groupb}</h3>
                </div>:null}
            </div>
          { responses.answersa || responses.answersb ? 
           <div className={'show-answers-content'} style={{flexDirection:'row',display:'flex'}}>
               <div style={{width:'50%'}}>
               {
                   responses.answersa.map(data => <div style={{width:'100%'}} className={'questions'}>
                       {type==='N' ? <img  style={{height:'20%',width:'20%'}} src={data}/>:<div>{data}</div>}
                   </div>)
               }
               </div>
               <div style={{width:'50%'}}>
               {
                   responses.answersb.map(data => <div style={{width:'100%'}} className={'responses'}>
                   {type==='N' ? <img  style={{height:'20%',width:'20%'}} src={data}/>:<div>{data}</div>}
               </div>)
               }</div>
            </div>
          :
          <div className={'show-answers-content'}>
                    {
                        responses.map((question,index) =>

                        <div style={{display:'flex',flexDirection:'row'}}>
                            <div className={'questions'}>
                                
                                <div>{!(question.constructor.name === 'Object') ? index :question.i ? <img  style={{height:'20%',width:'20%'}} src={question.i}/> 
                            : question.q ? question.q:question.o}</div>
                            </div>
                            <div className={'responses'}>
                                <div>{!(question.constructor.name === 'Object' )? question :!Array.isArray(question.a) ? question.a:question.a[1].t}</div>
                                <div style={success >50 ? {color:'green'}:{color:'red'}} className={'percentage'}><p>{success}%</p></div>
                                </div>
                        </div>)
                    }
           </div>}
        </div>
           
    )
}

export default ShowAnswers;