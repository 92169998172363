import React from "react";
import './questionBar.scss';

const QuestionBar = ({tittle,next}) => {

    return (
        <div className={'main-container-question-bar'} >
            <div></div>
            <p className={'tittle'}>{tittle}</p>
            <button onClick={next} className={'nextButton'}>&#5171;</button>
        </div>
    )
}

export default QuestionBar;