import React, {useEffect, useState} from "react";
import { useSpring, animated } from "react-spring";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer.scss';

const Timer = ({time = 0, limitedTime = 10, mobile}) => {
    const [timer, setTimer] = useState(time);

    const props = useSpring({
        config: {duration: 1000},
        from: {scale: 1},
        to: {scale: 1.1},
        loop: true,
    });

    useEffect(() => {
        if (!timer) return;

        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timer])

    return (<>
        {

            timer <= limitedTime && <animated.div
                style={timer > 0 ? props : {scale: 1, boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.3)'}}
                className={'timer-main'}>
                <CountdownCircleTimer
                    isPlaying
                    duration={limitedTime}
                    colors={[["#d26300", 0.33], ["#ff2a1b", 0.33], ["#d31d0d"]]}
                    size={mobile ? 43 : 60}
                    strokeWidth={2}
                >
                    {({remainingTime}) => <span style={{fontSize: 20}}>{remainingTime}</span>}
                </CountdownCircleTimer>
            </animated.div>
        }
    </>)

}

export default Timer;