import React, {useState, useEffect} from "react";
import {useSpring, animated} from "react-spring";
import "./orderSentence.scss";

import Layout from "../Layout";
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";

const Item = ({word, i, removeFromResult, checked, correct}) => {
    const [{background, color}, api] = useSpring(() => ({
        background: '#FEFEFE',
        color: 'black'
    }));

    useEffect(() => {
        let color = ''
        if (checked) {
            if (word === correct[i]) {
                color = '#27AE60'
            } else {
                color = '#E74C3C'
            }
            api.start({
                to: [
                    {
                        background: color,
                        color: 'white'
                    },
                    {
                        background: '#FEFEFE',
                        color: 'black'
                    }
                ],
            })
        }
    }, [checked, api, correct, word, i])

    return (
        <animated.div
            key={i}
            onClick={() => removeFromResult(i)}
            style={{background, color}}
            className={'word'}>
            {word}
        </animated.div>
    )
}

const OrderSentence = () => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [current, setCurrent] = useState({});
    const [result, setResult] = useState([]);
    const [options, setOptions] = useState([]);
    const [pressed, setPressed] = useState(false);
    const [original, setOriginal] = useState([]);

    const addToSentence = (i, word) => {
        let array = [...result];
        if (!array.find(item => item.i === i)) {
            array.push({i, word})
        }
        setResult(array);
    }

    const removeFromResult = (i) => {
        let array1 = [...result];
        array1.splice(i, 1);
        setResult(array1);
    }

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.a, arr);
            }
            let item = temp[randomNum(0, temp.length - 1)]
            const removeSelected = removeItemFromArr(temp.a, original);
            const extra = removeSelected[randomNum(0, removeSelected.length - 1)];
            setCurrent(item);
            setOptions(shuffleArray(item.a.split(' ').concat(extra.a.split(' '))))
        }
    };

    const check = () => {
        setPressed(true)
        setTimeout(() => {
            if (result.map(({word}) => word).join(' ') === current.a) {
                let temp = removeItemFromArr(current.a, questions);
                setQuestions(temp);
                next(temp);
                setProgressSteps(progressSteps + 1)
            } else {
                setLives(lives - 1)
            }
            setPressed(false);
            setResult([]);
        }, 400)
    }

    const setGameData = (data) => {
        setQuestions(data);
        setOriginal(data);
        const temp = data[randomNum(0, data.length - 1)];
        setCurrent(temp);
        const removeSelected = removeItemFromArr(temp.a, data);
        const extra = removeSelected[randomNum(0, removeSelected.length - 1)];
        setOptions(shuffleArray(temp.a.split(' ').concat(extra.a.split(' '))));
    }

    return (
        <Layout
            gameId={74181}
            currentStep={progressSteps}
            gameTitle={current.q}
            remainingLives={lives}
            reqGameData={setGameData}
            next={() => next(questions, true)}
        >
            <div className={'order-main'}>
                <div className={'order-container'}>
                    <div className={'words'}>
                        {result.map(({i, word}, index) => <Item
                            key={index}
                            word={word}
                            checked={pressed}
                            correct={current.a.split(" ")}
                            removeFromResult={removeFromResult}
                            i={index}
                        />)}
                    </div>
                    <div className={'hr'} />
                    <div className={'words-down'}>
                        {options.map((word, i) =>
                            <div
                                key={i}
                                onClick={() => addToSentence(i, word)}
                                style={result.find(item => item.i === i) && {color: ' rgb(240, 238, 238)'}}
                                className={'word-down'}
                            >
                                <p>{word}</p>
                            </div>)}
                    </div>
                    <div className={'button-div'}>
                        <button onClick={() => check()} className={'button-comprobar'}>Comprobar</button>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default OrderSentence;