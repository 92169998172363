import React, {useCallback, useContext, useEffect, useState} from "react";
import {useSpring, useSprings, animated} from "react-spring";
import {useDrag} from 'react-use-gesture';
import Color from '../../utils/global.module.scss';
import "./group.scss";

import Layout from "../../containers/Layout";
import ListenToKeyBoard from "../../components/ListenToKeyBoard";
import {shuffleArray} from "../../utils/helper";
import {DataContext} from "../../utils/context";

const Right = require('./flecha.png').default;
const correctColor = Color['correctColor'];
const wrongColor = Color['wrongColor'];

const toAnim = i => ({x: 0, y: i * -1, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100});

const Photos = ({
                    right, left, setLeft = () => {
    }, setRight = () => {
    }, response = () => {
    }, cards, withImage
                },) => {
    const {deviceWidth} = useContext(DataContext);
    const [gone] = useState(() => new Set());
    const Cards = cards;
    const [currentCard, setCurrentCard] = useState(Cards.length - 1);
    const [props, api] = useSprings(Cards.length, i => ({
        from: {x: 0, rot: 0, scale: 1.5, y: -1000},
        ...toAnim(i)
    }));

    const bind = useDrag(({args: [index], down, delta: [xDelta], movement: [xDir], velocity}) => {
        const trigger = velocity > 0.2;
        const dir = xDir < 0 ? -1 : 1;
        if (!down && trigger) {
            gone.add(index);
            corrections(index, dir);
            setCurrentCard(index - 1);
        }
        api.start(i => {
            if (index !== i) return
            const isGone = gone.has(index);
            const x = isGone ? (200 + deviceWidth) * dir : down ? xDelta : 0;
            const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0);
            const scale = down ? 1.1 : 1;
            return {x, rot, scale, delay: undefined, config: {friction: 50, tension: down ? 800 : isGone ? 200 : 500}};
        })
    });

    const corrections = useCallback((index, dir) => {
        let side = dir === 1 ? 'right' : 'left';
        let correct = null;

        if (cards[index].dir === side) {
            correct = true;
        }
        response(correct, side)
    }, [response, cards])

    useEffect(() => {
        const afterAnim = (dir) => {
            corrections(currentCard, dir);
            setCurrentCard(currentCard - 1);
            setLeft(false);
            setRight(false);
        }

        if (left || right) {
            let dir = left ? -1 : 1;
            gone.add(currentCard);
            api.start(i => {
                if (currentCard !== i) return
                const x = (200 + window.innerWidth) * dir;
                const rot = dir * 10;
                return {
                    x,
                    rot,
                    delay: undefined,
                    config: {
                        friction: 50,
                        tension: 200
                    },
                    reset: () => afterAnim(dir)
                };
            })
        }

    }, [left, right, api, gone, currentCard, corrections, setLeft, setRight])
    return props.map(({x, y, rot, scale}, i) => (
        <animated.div
            key={i}
            style={{
                x: x,
                y: y,
            }}
        >
            <animated.div
                {...bind(i)}
                style={{
                    x: x,
                    scale: scale,
                    transform: rot.to(rot => `perspective(1500px) rotateX(30deg) rotateY(${rot / 10}deg) rotateZ(${rot}deg)`),
                }}
            >
                <div
                    style={{
                        ...(withImage ? {backgroundImage: `url(${Cards[i].val})`} : {}),
                    }}
                >
                    {!withImage && Cards[i].val}
                </div>
            </animated.div>
        </animated.div>
    ))
}

const Group = ({gameId, withImage}) => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [leftClicked, setLeftClicked] = useState(false);
    const [rightClicked, setRightClicked] = useState(false);
    const [titles, setTitles] = useState({});
    const [cards, setCards] = useState([]);

    const [{bgLeft, bgRight, lScale, rScale}, api] = useSpring(() => ({
        bgLeft: 'transparent',
        bgRight: 'transparent',
        lScale: 1,
        rScale: 1
    }))

    const correction = (correct, side) => {
        if (correct) {
            setProgressSteps(progressSteps + 1)
        } else {
            setLives(lives - 1)
        }

        if (side === 'left') {
            api.start({
                from: {bgLeft: 'transparent'},
                to: correct ? [
                    {bgLeft: correctColor},
                    {bgLeft: 'transparent'},
                ] : [
                    {bgLeft: wrongColor},
                    {bgLeft: 'transparent'},
                ]
            })
        } else {
            api.start({
                from: {bgRight: 'transparent'},
                to: correct ? [
                    {bgRight: correctColor},
                    {bgRight: 'transparent'},
                ] : [
                    {bgRight: wrongColor},
                    {bgRight: 'transparent'},
                ]
            })
        }
    }

    const keyBoardInput = ({key}) => {
        if ('ArrowRight' === key) {
            setRightClicked(true);
            api.start({
                from: {rScale: 1},
                to: [
                    {rScale: 1.1},
                    {rScale: 1}
                ],
                config: {
                    duration: 200
                }
            });
            return;
        }

        if ('ArrowLeft' === key) {
            setLeftClicked(true);
            api.start({
                from: {lScale: 1},
                to: [
                    {lScale: 1.1},
                    {lScale: 1}
                ],
                config: {
                    duration: 200
                }
            });
        }
    }

    const setGameData = (data) => {
        const {answersa, answersb, header} = data;
        const left = answersa.map(item => ({dir: 'left', val: item}));
        const right = answersb.map(item => ({dir: 'right', val: item}));
        setCards(shuffleArray(left.concat(right)));
        setTitles(header);
    }

    return (
        <Layout
            gameId={gameId}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={setGameData}
        >
            <div
                className={'group-main-container'}
            >
                <ListenToKeyBoard
                    keyBoardInput={keyBoardInput}
                >
                    <div className={'columns'}>
                        <animated.div
                            style={{
                                background: bgLeft
                            }}
                        >
                            <div>
                                <h3>{titles.groupa}</h3>
                            </div>
                            <span>
                                <animated.img
                                    src={Right}
                                    alt={'left'}
                                    onMouseEnter={() => api.start({lScale: 1.1,})}
                                    onMouseLeave={() => api.start({lScale: 1,})}
                                    style={{scale: lScale,transform: 'rotate(180deg)'}}
                                    onClick={() => setLeftClicked(true)}
                                />
                            </span>
                        </animated.div>
                        <animated.div
                            style={{
                                background: bgRight
                            }}
                        >
                            <div>
                                <h3>{titles.groupb}</h3>
                            </div>
                            <span className={'left'}>
                                <animated.img
                                    src={Right}
                                    alt={'left'}
                                    onMouseEnter={() => api.start({rScale: 1.1,})}
                                    onMouseLeave={() => api.start({rScale: 1,})}
                                    style={{scale: rScale}}
                                    onClick={() => setRightClicked(true)}
                                />
                            </span>
                        </animated.div>
                    </div>
                    <div className={'container'}>
                        <Photos
                            response={correction}
                            left={leftClicked}
                            right={rightClicked}
                            setLeft={setLeftClicked}
                            setRight={setRightClicked}
                            cards={cards}
                            withImage={withImage}
                        />
                    </div>
                </ListenToKeyBoard>
            </div>
        </Layout>
    )
}

export default Group;