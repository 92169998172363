import React, {useRef, useState} from "react";
import {useDrag} from "react-use-gesture";
import {useResizeDetector} from "react-resize-detector";
import "./wordSearch.scss";

import Layout from "../Layout";
import {randomNum} from "../../utils/helper";

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
let gridData = [];
let matrix = [];
const gridMax = 12;

for (let i = 0; i < gridMax; i++) {
    let row = [];

    for (let j = 0; j < gridMax; j++) {
        matrix.push({location: [i, j], value: ''});
        row.push({location: [i, j], value: ''});
    }

    gridData.push(row);
}

const Cell = ({c, value, location, getSelection, validate}) => {
    const {width, ref} = useResizeDetector();
    const movedList = useRef([location]);

    const bind = useDrag(({args: [cord], active, movement: [x, y]}) => {
        const row = cord[0]
        const col = cord[1]

        const movedRow = (row * (width) + y) / (width);
        const movedCol = (col * width + x) / width;

        const closestRow = Math.round(movedRow);
        const closestCol = Math.round(movedCol);



        if (active && closestCol < gridMax && closestCol >= 0 && closestRow < gridMax && closestRow >= 0) {
            let i = movedList.current.findIndex(cell => cell.join('') === [closestRow, closestCol].join(''));

            if (i < 0) {
                movedList.current.push([closestRow, closestCol])
            } else if (i > 0 && i !== movedList.current.length - 1) {
                movedList.current = movedList.current.slice(0, i);
            }
            getSelection(movedList.current)
        } else {
            movedList.current = [location]
            validate()
        }
    })

    return <div
        ref={ref}
        {...bind(location)}
        className={'cell'}
        style={c ? {background: c} : {}}
    >
        {value}
    </div>
};

const WordSearch = () => {
    const [words, setWords] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [matrixData] = useState(matrix);
    const [grid, setGrid] = useState(gridData);
    const [selection, setSelection] = useState([])

    const process = (word, gridArr) => {
        let temp = [...gridArr];
        let orientation = randomNum(0, 1);
        let cord = matrixData[randomNum(0, matrixData.length - 1)].location;
        let x = cord[0];
        let y = cord[1];
        let BreakException = {};

        if (orientation === 0) {
            x =  ((x  + word.length) < gridMax) ? x : gridMax - word.length - 1;
            try {
                word.split('').forEach((char, i) => {
                    if (!(temp[x + i][y].value === char.toUpperCase() || temp[x + i][y].value === '')) {
                        throw BreakException;
                    }
                })

            } catch (e) {
                return process(word, gridArr);
            }

            word.split('').forEach((char, i) => {
                temp[x + i][y].value = char.toUpperCase();
            })
        } else {
            y = ((y + word.length) < gridMax) ? y : gridMax - word.length - 1;
            try {
                word.split('').forEach((char, i) => {
                    if (!(temp[x][y + i].value === char.toUpperCase() || temp[x][y + i].value === '')) {
                        throw BreakException;
                    }
                })
            } catch (e) {
                return process(word, gridArr);
            }

            word.split('').forEach((char, i) => {
                temp[x][y + i].value = char.toUpperCase();
            })
         }

        return temp;
    };

    const setGameData = (data) => {
        let tempGrid = [...grid];
        setWords(data.map(w => ({val: w.toUpperCase()})));

        data.forEach(item => {
            tempGrid = process(item, tempGrid);
        });

        tempGrid.map(rows => (rows.map(cell => {
            if (cell.value === '') {
                cell.value = letters[randomNum(0, letters.length - 1)]
            }

            return cell
        })));

        setGrid(tempGrid);
    };


    const getSelection = (arr) => {
        let temp = grid.map(row => row.map(cell => {
            if (!cell.corrected) {
                cell.c = 'white';
            }
            return cell
        }));

        arr.forEach(item => {
            temp[item[0]][item[1]].c = 'grey';
        });
        setGrid(temp);
        setSelection(arr);
    }

    const validate = () => {
        let temp = [...grid];
        let cArr = [...words];
        let word = '';

        selection.forEach(item => {
            word += temp[item[0]][item[1]].value
        })

        let index = cArr.findIndex(({val}) => val === word || val === word.split('').reverse().join(''));

        if (index > -1) {
            cArr[index].corrected = true;
            selection.forEach(item => {
                temp[item[0]][item[1]].c = 'teal';
                temp[item[0]][item[1]].corrected = 'true';
            });
            setGrid(temp)
            setProgressSteps(progressSteps + 1);
            setWords(cArr);
        } else {
            selection.forEach(item => {
                temp[item[0]][item[1]].c = 'red';
            });
            setGrid(temp)
            setTimeout(() => {
                let temp = [...grid];
                selection.forEach(item => {
                    temp[item[0]][item[1]].c = temp[item[0]][item[1]].corrected ? 'teal' : 'white';
                });
                setGrid(temp)
            }, 500)
        }
    }

    return (
        <Layout
            gameId={76239}
            currentStep={progressSteps}
            remainingLives={null}
            reqGameData={setGameData}
        >
            <div className={'game-word-search-container'}>
                <div className={'matrix-container'}>
                    <div className={'cell-wrapper'}>
                        {
                            grid.map((row, rowIndex) =>
                                <div key={rowIndex} className={'row'}>
                                    {
                                        row.map(({value, c, location}, i) =>
                                            <Cell
                                                key={i}
                                                c={c}
                                                value={value}
                                                location={location}
                                                getSelection={getSelection}
                                                validate={validate}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={'words-container'}>
                    {
                        words.map((word, i) =>
                            <div
                                key={i}
                                className={'word-container'}
                            >
                                <p>{word.corrected && word.val}</p>
                            </div>
                        )
                    }
                </div>

            </div>

        </Layout>
    );
};

export default WordSearch;
