import React, {useEffect, useRef, useState} from "react";
import './keyBoardButtons.scss';

import Button from "../Button";

const Space = () => {
    return (<span style={{width: 20}}/>)
}

const KeyBoardButtons =
    ({
         answerSlots = [],
         answerOpts = [],
         correctAnswer = '',
         incorrectTry = () => {
         },
         goToNextGame = () => {
         },
     }) => {
        const [answer, setAnswer] = useState(answerSlots);
        const [correct, setCorrect] = useState(null);
        const listenToKeyboard = useRef();

        const assignAnswer = (val) => {
            let ansArr = [...answer];
            const index = ansArr.findIndex(item => item === '');

            if (index > -1) {
                ansArr[index] = val;
                setAnswer(ansArr);
            }

            if (!ansArr.filter(item => (item === '')).length) {
                let incorrect = false;

                if (correctAnswer.trim().toUpperCase() === ansArr.map(item => item === 'space' ? ' ' : item).join('')) {
                    setCorrect(true);
                    goToNextGame();
                } else {
                    incorrect = true;
                    setCorrect(false);
                    incorrectTry();
                }

                setTimeout(() => {
                    setCorrect(null);
                    if (incorrect) {
                        setAnswer(answer.map(item => item === 'space' ? item : ''));
                    }
                }, 300)
            }
        }

        const removeAnswer  = (id) => {
            let ansArr = [...answer];
            ansArr[id] = '';
            setAnswer(ansArr);
        }

        const keyBoardInput = ({key}) => {
            const value = key.toUpperCase();
            const index = answerOpts.findIndex(item => value === item);
            if (index >= 0) {
                assignAnswer(value)
            }
        }

        useEffect(() => {
            setAnswer(answerSlots);
        }, [answerSlots])

        useEffect(() => {
            listenToKeyboard.current.focus();
        }, [answerSlots])

        return (
            <div
                tabIndex={0}
                className={'key-board-buttons-main'}
                onKeyDown={keyBoardInput}
                ref={listenToKeyboard}
            >
                <div className={'answer-area'}>
                    {
                        answer.map((item, index) => (item !== 'space') ?
                            <Button
                                key={index}
                                id={index}
                                isAnswer
                                value={item}
                                onClick={removeAnswer}
                                corrected={correct}
                                animation={false}
                            />
                            : <Space key={index}/>
                        )
                    }

                </div>
                <div className={'options-area'}>
                    {
                        answerOpts.map((item, index) =>
                            <Button key={index} value={item} animation={false} onClick={assignAnswer} style={{marginBottom:30,boxShadow:' 2px 2px 2px 1px rgba(0, 0, 0, 0.4)'}}/>
                        )
                    }
                </div>
            </div>
        )
    }

export default KeyBoardButtons;