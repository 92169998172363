import React, {useEffect, useRef} from "react";

const ListenToKeyBoard = ({children, keyBoardInput = () => {}}) => {
    const listenToKeyboard = useRef();

    useEffect(() => {
        listenToKeyboard.current.focus();
    })

    return (
        <div
            tabIndex={0}
            onKeyDown={keyBoardInput}
            ref={listenToKeyboard}
        >
            {
                children
            }
        </div>
    )
}

export default ListenToKeyBoard;