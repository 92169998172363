import React, {useEffect} from "react";

import sound from  './score a point game.mp3';
const point = new Audio(sound);

const PointScored = ({score = 0}) => {

    const playAudio = () => {
        point.play().then(() => {console.log('yahoo!')});
    }

    useEffect(() => {
        if (score) {
            playAudio()
        }
    }, [score])

    return <></>;
}

export default PointScored