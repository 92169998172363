import React, {useContext, useEffect, useState} from "react";
import './layout.scss';
import Header from "../../components/Header";
import StepCounter from "../../components/StepCounter";
import ProgressBar from "../../components/ProgressBar";
import CloseButton from "../../components/CloseButton";
import Timer from "../../components/Timer";
import Hearts from "../../components/Hearts";
import QuestionBar from "../../components/QuestionBar";
import PointScored from "../../components/PonitScored/PointScored";
import {DataContext} from "../../utils/context";
import {GetData} from "../../utils/Api";
import { useHistory } from 'react-router-dom';

const Layout = ({gameId, next, currentStep, remainingLives, gameTitle, children, reqGameData = () => {}, allData = null}) => {
    const {deviceWidth} = useContext(DataContext);
    const {deviceHeight} = useContext(DataContext);
    const [gameData, setGameData] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [fails, setFails] = useState(null);
    const [time, setTime] = useState(null);
    const [gTitle, setGTitle] = useState(null);
    const [loading, setLoading] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [color, setColor] = useState('white');

    const history = useHistory();

    console.log(gameData)
    useEffect(() => {
        let isActive = true;

        if (!gameData && gameId && !loading) {
            GetData(gameId)
                .then(res => {
                    setLoading(true);
                    if (isActive) {
                        const {gamedef, maxfails, maxtime, title, background} = res;

                        if (gamedef.questions) {
                            reqGameData(gamedef.questions);
                            setQuestions(gamedef.questions.length);
                        } else {
                            const {corrects, answersa, answersb} = gamedef;
                            const total = corrects ? corrects.length : (answersa.length + answersb.length);
                            setQuestions(total);
                            reqGameData(gamedef);
                        }
                        setFails(maxfails);
                        setTime(1000);
                        setGTitle(title);
                        setGameData(res);
                        setBackgroundImage(background.img);
                        setColor(background.color)
                        allData && allData(gamedef);
                    }
                });
        }
        return () => {
            isActive = false;
        };
    }, [gameData, gameId, reqGameData, loading, allData]);

    return (
        <div className={'layout-main'}>
            {
                gameData && <>
                    <Header>
                        <StepCounter current={currentStep} steps={questions}/>
                        <h1>{gTitle}</h1>
                        <Hearts lives={remainingLives === null ? 0 : fails} remaining={fails + remainingLives}/>
                    </Header>
                    <ProgressBar steps={questions} currentStep={currentStep}/>
                    {gameTitle && <QuestionBar next={next} tittle={gameTitle}/>}
                    <PointScored
                        score={currentStep}
                    />
                    <div
                        style={{
                            background: '#e6f4da',
                        }}
                        className={'container-wrapper'}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${backgroundImage})`
                            }}
                            className={'withBackground'}
                        />
                        <div
                            className={'container'}
                        >
                            {children}
                        </div>
                    </div>
                    <CloseButton onClick={() => history.push({
                        pathname: '/ShowAnswers',
                        state: {
                          responses: gameData.gamedef.answersa ? gameData.gamedef : gameData.gamedef.questions ? gameData.gamedef.questions:gameData.gamedef.corrects ,
                          gameType:gameData.gameType
                        }
                    })}/>
                    {
                        time && <Timer mobile={576 > deviceWidth || 576 > deviceHeight} time={time} limitedTime={10}/>
                    }
                </>
            }
        </div>
    )
}

export default Layout;