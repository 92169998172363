import React, {useContext, useEffect, useState} from "react";
import { useSpring, animated } from "react-spring";
import './imagesGroup.scss';

import {DataContext} from "../../utils/context";
import Color from "../../utils/global.module.scss";

const correctColor = Color['correctColor'];
const wrongColor = Color['wrongColor'];

const Image = ({size, check, img, onCorrect = () => {}, onWrong = () => {}}) => {
    const [{opacity, background}, api] = useSpring(() => ({
        opacity: 0,
        background: 'transparent'
    }));

    const style = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    };

    const click = () => {
        if (check) {
            api.start({
                to: [
                    {
                        opacity: 0.5,
                        background: correctColor
                    },
                    {
                        opacity: 0,
                        background: 'transparent'
                    }
                ]
            })
            onCorrect()
        } else {
            api.start({
                to: [
                    {
                        opacity: 0.5,
                        background: wrongColor
                    },
                    {
                        opacity: 0,
                        background: 'transparent'
                    }
                ]
            })
            onWrong()
        }
    }

    return (
        <div
            className={'single-image'}
            style={{width: size, height: size}}
        >
           <div
               style={style}
               className={'img'}
           />
            <animated.div className={'overlay'} onClick={click} style={{opacity, background}}/>
        </div>
    )
}

const ImagesGroup = ({imageList, onCorrect = () => {}, onWrong = () => {}}) => {
    const {deviceWidth, deviceHeight} = useContext(DataContext);
    const [size, setSize] = useState(null);

    useEffect(() => {
        if (deviceWidth) {
            if (deviceWidth > deviceHeight) {
                setSize((deviceHeight/(deviceHeight < 450 ? 2.3 : 2.7)))
            } else {
                setSize(((deviceWidth)/(deviceWidth > 577 ? 2.5 : 2.1)) - 10)
            }
        }
    }, [deviceWidth, deviceHeight])

    return (
       <div className={'container-image-select-wrapper'}>
           <div  className={'container-image-select'}>
               {
                   imageList.map(({img, correct}, i) => <Image
                       size={size}
                       key={i}
                       img={img}
                       check={correct}
                       onCorrect={onCorrect}
                       onWrong={onWrong}
                   />)
               }
           </div>
       </div>
    )
}

export default ImagesGroup;