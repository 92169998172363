import React, {useState} from "react";
import './gameTestImage.scss';

import Layout from "../Layout"
import {randomNum, removeItemFromArr} from "../../utils/helper";
import GameText from "../../components/GameText";

const GameTestImage = (props) => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [current, setCurrent] = useState({});

    const check = (correct) => {
        if (correct) {
            setProgressSteps(progressSteps + 1)
            setTimeout(() => {
                let temp = removeItemFromArr(current.i, questions, 'i');
                setQuestions(temp);
                next(temp);
            }, 500);
        } else {
            setLives(lives - 1);
        }
    }

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.i, arr, 'i');
            }
            setCurrent(temp[randomNum(0, temp.length - 1)]);
        }
    };

    const setGameData = (data) => {
        setQuestions(data);
        setCurrent(data[randomNum(0, data.length - 1)]);
    }

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            gameTitle={current.q}
            remainingLives={lives}
            next={() => next(questions, true)}
            reqGameData={setGameData}
        >
            <div className={'image-game-container'}>
                <div className={'image'}><img src={current.i} alt={'its a description'}/></div>
                <GameText
                    className={'custom-styles'}
                    current={current}
                    check={check}
                />
            </div>
        </Layout>

    )
}

export default GameTestImage;