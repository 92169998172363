import React from "react";

import Group from "../../components/Group";

const GroupText = (props) => {
    return (
        <Group
            gameId={props.location.state.id}
        />
    )
}

export default GroupText;