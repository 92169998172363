import React, {useContext, useEffect, useState} from "react";
import './coupleText.scss';

import Layout from "../Layout";
import {shuffleArray} from "../../utils/helper";
import {DataContext} from "../../utils/context";
import {useResizeDetector} from "react-resize-detector";
import {animated, useSpring} from "react-spring";
import {useDrag} from "react-use-gesture";

const Smile = require('./noun_Smile_534862.svg').default;

const Item = ({
                  blank,
                  color,
                  item,
                  index,
                  ans,
                  correctAns = () => {},
                  wrongAns = () => {},
                  wrong,
                  gotCorrect,
                  data
              }) => {
    const {deviceHeight} = useContext(DataContext);
    const {width, ref} = useResizeDetector();
    const [correct, setCorrect] = useState(false)
    const [text, setText] = useState(item)
    const height = deviceHeight > 630 ? 100 : (deviceHeight > 615 ? 70 : (deviceHeight > 415 ? 50 : 40));
    const padding = deviceHeight > 540 ? 50 : (deviceHeight > 415 ? 20 : 15);

    const YOffsetsBelow = 0.2;
    const YOffsetsAbove = 0.4;
    const XOffsetBehind = 0.3;
    const XOffsetInFrontOf = 0.8;

    const [
        {y, x, scale, zIndex, shadow, immediate, display, background, opacity, transform, opacityB},
        api
    ] = useSpring(() => ({
        y: 0,
        x: 0,
        scale: 1,
        zIndex: 0,
        shadow: 1,
        opacity: 1,
        opacityB: 0,
        immediate: false,
        display: 'flex',
        background: color,
        transform: `rotateY(0deg)`,
    }));

    const bind = useDrag(({args: [location], active, movement: [x, y]}) => {
        const row = location[0]
        const col = location[1]

        const movedRow = (row * (height + padding + 6) + y) / (height + padding + 6);
        const movedCol = (col * width + x) / width;

        const closestRow = Math.round(movedRow);
        const closestCol = Math.round(movedCol);

        const closestRowBelow = closestRow + YOffsetsBelow;
        const closestRowAbove = closestRow - YOffsetsAbove;

        const closestColBehind = closestCol + XOffsetBehind;
        const closestColInFrontOf = closestCol - XOffsetInFrontOf;

        if (!(closestCol === col && closestRow === row) &&
            closestColBehind > movedCol &&
            closestColInFrontOf < movedCol &&
            closestRowBelow > movedRow &&
            closestRowAbove < movedRow && !active) {

            if (data[closestRow] && data[closestRow].children[closestCol] && ans === data[closestRow].children[closestCol].value) {
                correctAns({
                    ans: [closestRow, closestCol],
                    current: [row, col],
                    value: item,
                    color
                });
                setCorrect(true);
                api.start({display: 'none'});
            } else {
                wrongAns([closestRow, closestCol])
            }
        }

        if (active) {
            api.start({
                y: y,
                x: x,
                scale: 1.1,
                zIndex: 1,
                shadow: 15,
                immediate: (key) => key === 'y' || key === 'zIndex' || key === 'x',
            })
        } else {
            api.start({
                y: 0,
                x: 0,
                scale: 1,
                zIndex: 0,
                shadow: 1,
            })
        }

    })

    useEffect(() => {
        if (!!gotCorrect) {
            api.start({
                to: [
                    {
                        background: gotCorrect.color,
                        config: {
                            duration: 200
                        }
                    },
                    {
                        opacity: 0,
                        opacityB: 1,
                        transform: `rotateY(180deg)`,
                        immediate: (key) => key === 'background',
                        config: {
                            duration: 500,
                            mass: 5,
                            tension: 500,
                            friction: 80
                        },
                        delay: 100
                    },
                    {
                        opacityB: 0,
                        display: 'none',
                        config: {
                            duration: 200
                        }
                    }
                ]
            })
            setText(gotCorrect.value)
        }
    }, [gotCorrect, api, color]);

    useEffect(() => {
        if (wrong) {
            api.start({
                to: [{background: 'red'}, {background: color}],
                config: {
                    duration: 500
                },
            })
        }
    }, [wrong, api, color])

    return (
        <div className={'wrapper'}>
            {
                (correct || blank) ?
                    <div className={'box'} style={{height}}/>
                    :
                    <>
                        <animated.div
                            className={`box back`}
                            style={{opacity: opacityB, transform, height}}
                        >
                            <img src={Smile} alt={'correct'} width={35}/>
                        </animated.div>
                        <animated.div
                            {...bind(index)}
                            ref={ref}
                            style={{
                                zIndex,
                                boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                                y,
                                x,
                                scale,
                                display,
                                background,
                                immediate,
                                opacity,
                                transform,
                                height
                            }}
                            className={'box'}
                        >
                            <span>{text}</span>
                        </animated.div>
                    </>
            }
        </div>
    )
}


const Wrapper = ({children}) => {
    const {deviceHeight} = useContext(DataContext);

    return <div
        style={{paddingBottom: (deviceHeight > 415 ? 40 : 20)}}
        className={'couple-text-main'}
    >
        {children}
    </div>
}

const Cell = ({children}) => {
    const {deviceHeight} = useContext(DataContext);

    return <div
        style={{paddingTop: (deviceHeight > 540 ? 30 : (deviceHeight > 415 ? 20 : 15))}}
        className={'items-row'}
    >
        {children}
    </div>
}

const CoupleText = (props) => {
    const [questions, setQuestions] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);

    const setGameData = (data) => {
        let newArr = [];
        let ansArr = [];
        let questionArr = [];
        let temp = [...data];
        temp.forEach(({q, a}) => {
            newArr.push({value: q, ans: a})
            newArr.push({value: a, ans: q})

            questionArr.push({value: q, ans: a, type: 'white'});
            ansArr.push({value: a, ans: q, type: 'lightgrey'});
        });
        temp = shuffleArray(newArr);
        newArr = [];
        let questionTemp = shuffleArray(questionArr);
        let answerTemp = shuffleArray(ansArr);

        while (questionTemp.length) {
            let row1 = questionTemp.splice(0, 4);
            let row2 = answerTemp.splice(0, 4);
            if (row1.length < 2) {
                newArr.push([{blank: true}].concat(row1.concat(row2)));
            } else {
                newArr.push(row1);
                newArr.push(row2);
            }
        }

        setQuestions(newArr.map(item => ({children: item})));
    };

    const gotCorrect = ({ans, color, current, value}) => {
        let temp = [...questions];
        temp[ans[0]].children[ans[1]].correct = {color, value};
        temp[current[0]].children[current[1]].correct = true;
        setQuestions(temp);
        setProgressSteps(progressSteps + 1)
    }

    const gotWrong = (ans) => {
        let temp = [...questions];
        if (temp[ans[0]] && temp[ans[0]].children[ans[1]]) {
            temp[ans[0]].children[ans[1]].wrong = true;
            setQuestions(temp)
            setTimeout(() => {
                temp = [...questions];
                temp[ans[0]].children[ans[1]].wrong = false;
                setQuestions(temp);
            }, 300);
            setLives(lives - 1);
        }
    }

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={setGameData}
        >
            <div className={'couple-text-main-wrapper'}>
                <Wrapper>
                    {
                        questions.map(({type, children}, i) =>
                            <Cell key={i}>
                                {
                                    children.map((item, index) =>
                                        <Item
                                            blank={item.blank}
                                            data={questions}
                                            key={index}
                                            item={item.value}
                                            index={[i, index]}
                                            color={item.type}
                                            ans={item.ans}
                                            wrongAns={gotWrong}
                                            correctAns={gotCorrect}
                                            wrong={item.wrong}
                                            gotCorrect={item.correct}
                                        />
                                    )
                                }
                            </Cell>
                        )
                    }
                </Wrapper>
            </div>
        </Layout>
    )
}

export default CoupleText;