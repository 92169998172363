import React from "react";
import './imageFrame.scss';

import NextButton from "../NextButton";

const ImageFrame = ({source, onClick}) => {

    const img = {
        backgroundImage: `url(${source})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    };

    return (
        <div
            className={'single-image-frame'}
        >
            <div className={'content'}>
                <div className={'image-cover'}>
                    <div
                        style={{ ...img}}
                        className={'image'}
                    />
                </div>
                <NextButton onClick={onClick} />
            </div>
        </div>
    )
}

export default ImageFrame