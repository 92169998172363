import React, {useCallback, useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import './button.scss';

const Button = (
    {
        value = null,
        isAnswer = false,
        onClick = () => {
        },
        id = null,
        corrected = null,
        style = {},
        correct = false,
        stayCorrect = false,
        className = '',
        animation = true
    }
) => {
    const [pressed, setPressed] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const btnBackground = useCallback(() => (!value ? '#EDEDED' : '#FEFEFE'), [value]);

    const [{scale, background, color}, api] = useSpring(() => ({
        scale: 1,
        color: 'black',
        background: btnBackground(),
        config: {
            duration: 80
        }
    }));

    const resetAnimation = useCallback(() => {
        if (stayCorrect && correct) {
            setDisabled(true);
            return
        }
        setPressed(false);
    }, [stayCorrect, correct]);

    const Click = () => {
        setPressed(true);
        if (isAnswer) {
            value && onClick(id)
        } else {
            onClick(value)
        }
    }

    useEffect(() => {
        if (pressed && !disabled) {
            api.start({
                to: [
                    {
                        scale: 0.95,
                        background: animation ? (correct ? 'teal' : 'red') : btnBackground(),
                        color: animation ? 'white': 'black',
                        config: {
                            duration: 200
                        }
                    },
                    {
                        scale: 1,
                        color: correct && stayCorrect ? 'white' : 'black',
                        background: correct && stayCorrect ? 'teal' : btnBackground(),
                        delay: 100
                    },
                ],
                reset: true,
                onRest: resetAnimation,
            });
        }

        if (!pressed) {
            api.start({
                background: btnBackground(),
                config: {
                    duration: 200
                }
            });
        }

    }, [pressed, api, animation, correct, btnBackground, stayCorrect, disabled, resetAnimation]);

    useEffect(() => {
        if (corrected !== null) {
            api.start({
                to: [
                    {
                        background: (corrected ? 'teal' : 'red'),
                        config: {
                            duration: 200
                        }
                    },
                    {
                        background: btnBackground(),
                    }
                ]
            });
        }
    }, [corrected, api, btnBackground]);

    return (
        <animated.div
            className={`button-main ${className}`}
            onClick={() => !pressed && !disabled && Click()}
            style={{scale, background, color, ...style}}
        >
            <span>{value}</span>
        </animated.div>
    )
}

export default Button;