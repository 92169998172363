import React from "react";
import { useSpring, animated } from 'react-spring'
import './progressBar.scss';

const ProgressBar = ({steps = 5, currentStep = 0}) => {
    const styles = useSpring({
        to: {width: `${(100*currentStep)/steps}%`},
        from: { width: '0%' },
        config: {
            duration: 500
        }
    })

    return (
        <div className="progress-bar-main">
            <animated.div style={styles} className="progress-bar" />
        </div>
    );
}

export default ProgressBar;