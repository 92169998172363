import React from "react";
import {useSpring, animated} from "react-spring";
import './gameText.scss';

import Button from "../../components/Button";

const Response = ({next = () => {}, children, correct}) => {
    const {x} = useSpring({
        from: {x: 0},
        x: 1,
        config: {duration: 1000},
    });

    return (
        <animated.div
            className={`text-container`}
            onClick={() => next(correct)}
            style={{
                scale: x.to({
                    range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                    output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],

                }),
            }}
        >
            {children}
        </animated.div>
    )
}

const GameText = ({current, check, className = ''}) => {
    return (
        <div className={`response-container-game-text-main ${className}`}>
            {
                current.a && current.a.map((item, index) =>
                    <Response
                        key={index}
                        current={current}
                        next={check}
                        item={item}
                        correct={item.o === current.c}
                    >
                        <Button
                            className={'btn-style'}
                            value={item.t}
                            correct={item.o === current.c}
                        />
                    </Response>)
            }
        </div>
    )
}

export default GameText;