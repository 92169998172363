import React, {useRef, useEffect, useState, useContext, useImperativeHandle, forwardRef} from 'react'
import {useSprings, animated, useSpring} from 'react-spring';
import {useDrag} from 'react-use-gesture';
import clamp from 'lodash.clamp';
import swap from 'lodash-move';
import Color from '../../utils/global.module.scss';
import './ranking.scss';

import Layout from "../Layout";
import {DataContext} from "../../utils/context";
import {shuffleArray} from "../../utils/helper";

const correctColor = Color['correctColor'];
const wrongColor = Color['wrongColor'];

const Item = ({i, zIndex, shadow, y, scale, items, bind, checked = false, order, height, correction}) => {
    const [{background, color}, api] = useSpring(() => ({
        background: '#FEFEFE',
    }));
    useEffect(() => {
        let color = ''
        if (checked) {
            let answer = (items[i].o - 1  === order.indexOf(i));
            correction(answer);

            color = answer ? correctColor : wrongColor;

            api.start({
                to: [
                    {
                        background: color,
                        color: 'white'
                    },
                    {
                        background: '#FEFEFE',
                        color: 'black'
                    }
                ],
            })
        }
    }, [checked, api, i, order, items, correction]);

    return (<animated.div
        {...bind(i)}
        key={i}
        style={{
            background,
            color,
            zIndex,
            boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            y,
            scale,
            height,
        }}
        children={items[i].a}
    />)
}

const Rank = forwardRef(({items, pressed, validate}, ref) => {
    const {deviceWidth, deviceHeight} = useContext(DataContext);
    const order = useRef(items.map((_, i) => i));
    const [springs, api] = useSprings(items.length, fn(order.current));
    const [itemWidth, setItemWidth] = useState(60);
    const [answers, setAnswers] = useState([]);

    const bind = useDrag(({args: [originalIndex], active, movement: [, y]}) => {
        const curIndex = order.current.indexOf(originalIndex);
        const curRow = clamp(Math.round((curIndex * itemWidth + y) / itemWidth), 0, items.length - 1);
        const newOrder = swap(order.current, curIndex, curRow);
        api.start(fn(newOrder, active, originalIndex, curIndex, y));
        if (!active) order.current = newOrder;
    });

    const correction = (value) => {
        let temp = answers;
        temp.push(value);
        setAnswers(temp);
    }

    useImperativeHandle(ref, () => ({
        getAlert() {
            if (answers.length === items.length) {
                let temp = [...answers];
                validate(temp);
                setAnswers([])
            }
        }
    }));

    useEffect(() => {
        setItemWidth(deviceHeight <= 414 ? 36 : deviceWidth <= 480 ? 50 : 60);
        api.start(fn(order.current))
    }, [deviceWidth, deviceHeight, api]);

    if (!deviceWidth) {
        return null;
    }

    return <div className={'soring-area'}>
        <div
            style={{width: itemWidth}}
            className={'col-numbers'}
        >
            {
                items.map((item, i) =>
                    <div
                        style={{ height: itemWidth - 4}}
                        key={i}>
                        {i + 1}º
                    </div>)
            }
        </div>
        <div className={'col-list'}>
            <div className={'list'}>
                {springs.map(({zIndex, shadow, y, scale}, i) => (
                    <Item
                        key={i}
                        i={i}
                        checked={pressed}
                        order={order.current}
                        zIndex={zIndex}
                        shadow={shadow}
                        y={y}
                        height={itemWidth - 4}
                        scale={scale}
                        items={items}
                        bind={bind}
                        correction={correction}
                    />
                ))}
            </div>
        </div>
    </div>
})

const fn = (order, active = false, originalIndex = 0, curIndex = 0, y = 0) => (index) =>
    active && index === originalIndex
        ? {
            y: window.innerHeight <= 414 ? curIndex * 38 + y : window.innerWidth <= 414 ? order.indexOf(index) * 56 : curIndex * 62 + y,
            scale: 1.1,
            zIndex: 100,
            shadow: 15,
            immediate: (key) => key === 'y' || key === 'zIndex',
        }
        : {
            y: window.innerHeight <= 414 ? order.indexOf(index) * 38 : window.innerWidth <= 414 ? order.indexOf(index) * 56 : order.indexOf(index) * 62,
            scale: 1,
            zIndex: 0,
            shadow: 1,
            immediate: false,
        }

const Ranking = (props) => {
    const [items, setItems] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [pressed, setPressed] = useState(false);
    const childRef = useRef();

    const Check = () => {
        setPressed(true)
        setTimeout(() => {
            setPressed(false);
            childRef.current.getAlert()
        }, 300);
    };

    const setGameData = (data) => {
        setItems(shuffleArray(data));
    }

    const validate = (arr) => {
        let failed = arr.find(item => !item);
        if (failed === false) {
            setLives(lives - 1);
        } else {
            setProgressSteps(arr.length);
        }

    }

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            remainingLives={lives}
            goToNextGame={() => setProgressSteps(1 + progressSteps)}
            reqGameData={setGameData}
        >
            <div className={'ranking-container-main'}>
                <div className={'wrapper'}>

                    <Rank
                        ref={childRef}
                        items={items}
                        pressed={pressed}
                        validate={validate}
                    />
                    <div className={'button-ranking'} onClick={() => Check()}>
                        Comprobar
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Ranking;
