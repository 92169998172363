import axios from 'axios';

const URL = 'https://academons.com/gameapi/index.php/gameapi';

export const GetData = (gameId) => {
    return axios.get(`${URL}/${gameId}`,)
        .then(req => {
            const {game, errno} = req.data
            if (!errno) {
                return game
            }
            return null;
        })
        .catch(err => {
            console.log('Error in request: ', err);
            return err
        });
}