import React from "react";

import Group from "../../components/Group";

const GroupGame = (props) => {

    return (
        <Group
            gameId={props.location.state.id}
            withImage
        />
    )
}

export default GroupGame;