import React from "react";
import './header.scss';

const Header = ({children}) => {

    return (
        <div className={'main-header'}>
            {children}
        </div>
    )
}

export default Header;