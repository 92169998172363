import React, {useEffect, useState} from "react";
import { useSpring, animated } from "react-spring";
import './hearts.scss';

import sound from  './Duck Quack.mp3';

const heart = require('./noun_Heart.svg').default;
const outlinedHeart = require('./noun_Heart_outline.svg').default;
const quackMp3 = new Audio(sound);

const Heart = ({alive = true}) => {
    const [played, setPlayed] = useState(false);
    const props = useSpring({
        opacity: alive ? 1 : 0,
    })
    const { x } = useSpring({
        from: { x: 0 },
        x: alive ? 1 : 0,
        config: { duration: 1000 },
    });

    const playAudio = () => {
        quackMp3.play().then(() => {console.log('quack!')});
    }

    useEffect(() => {
        if (!played && !alive) {
            playAudio();
            setPlayed(true);
        }
    }, [alive, played])

    return (
        <div className={'one-heart'}>
            <animated.img src={heart} alt={'heart'} width={25} className={'full'} style={props}/>
            <animated.img src={outlinedHeart} alt={'heart'} width={25} className={'dead'} style={{
                scale: x.to({
                    range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                    output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                }),
            }}/>
        </div>
    )
}

const Hearts = ({lives = 3, remaining}) => {
    const [livesArr, setLivesArr] = useState([]);

    useEffect(() => {
        let temp = []
        for(let i = 0; i < lives; i++) {
            temp.push(i)
        }
        setLivesArr(temp)

    }, [lives])

    return (<div className={'heart-meeter-main'}>
        {
            livesArr.map((item) =>  <Heart key={item} alive={item < remaining}/>)
        }
    </div>)
}

export default Hearts;