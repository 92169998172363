import React from "react";
import {useSpring, animated} from "react-spring";
import './closeButton.scss';

const close = require('./noun_Close_3039071.svg').default;

const defaultStyle = {
    scale: 1,
    background: 'white',
    filter: 'brightness(1) invert(0)'
};

const CloseButton = ({onClick = () => {}}) => {
    const [{scale, boxShadow, background, filter}, api] = useSpring(() => (defaultStyle));

    return (<animated.div
        onMouseEnter={() => api.start({
            scale: 1.1,
            background: 'black',
            filter: 'brightness(0) invert(1)'
        })}
        onMouseLeave={() => api.start(defaultStyle)}
        className={'close-button-main'}
        style={{ scale, boxShadow, background }}
        onClick={onClick}
    >
        <animated.img src={close} alt={'close'} style={{filter}}/>
    </animated.div>)
}

export default CloseButton;