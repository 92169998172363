import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import './app.scss';

import {Provider} from "./utils/context";
import SecretWords from "./containers/SecretWords";
import IdentifyImage from "./containers/IdentifyImage";
import CoupleText from './containers/CoupleText';
import SelectAnswers from './containers/SelectAnswers';
import MatchImages from "./containers/MatchImages";
import Carousel from "./containers/Carousel";
import GameTextTest from "./containers/GameTestText/index"
import GameTestImage from "./containers/GameTestImage";
import Map from './containers/Map';
import OrderSentence from "./containers/OrderSentence";
import Group from "./containers/Group";
import CatchMe from "./containers/CatchMe";
import Ranking from "./containers/Ranking";
import WhiteList from "./containers/WhiteList";
import WordSearch from "./containers/WordSearch";
import GroupText from "./containers/GroupText";
import Home from "./containers/Home"
import ShowAnswers from "./containers/ShowAnswers"

function App() {

    return (
        <div className="App">
            <Provider>
                <Router>
                    <Redirect from="/" to="/Home" />
                    <Switch>
                        <Route exact path="/Home" component={Home} />
                        <Route exact path="/Carousel" component={Carousel} />
                        <Route exact path="/MatchImages" component={MatchImages} />
                        <Route exact path="/Ranking" component={Ranking} />
                        <Route exact path="/SecretWords" component={SecretWords} />
                        <Route exact path="/WhiteList" component={WhiteList} />
                        <Route exact path="/Group" component={Group} />
                        <Route exact path="/GroupText" component={GroupText} />
                        <Route exact path="/IdentifyImage" component={IdentifyImage} />
                        <Route exact path="/SelectAnswers" component={SelectAnswers} />
                        <Route exact path="/OrderSentence" component={OrderSentence} />
                        <Route exact path="/GameTextTest" component={GameTextTest} />
                        <Route exact path="/GameTestImage" component={GameTestImage} />
                        <Route exact path="/CoupleText" component={CoupleText} />
                        <Route exact path="/CatchMe" component={CatchMe} />
                        <Route exact path="/Map" component={Map} />
                        <Route exact path="/WordSearch" component={WordSearch} />
                        <Route exact path="/ShowAnswers" component={ShowAnswers} />
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
