import React, {useState} from "react";
import sampleSize from "lodash.samplesize";
import './matchImages.scss';

import Layout from "../Layout";
import ImagesGroup from '../../components/ImagesGroup';
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";

const MatchImages = () => {
    const [questions, setQuestions] = useState([]);
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState({});
    const [answers, setAnswers] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);

    const process = (array, data) => {
        let item = array[randomNum(0, array.length - 1)];
        let result = sampleSize(removeItemFromArr(item.a, data), 3);
        result.push(item);
        setAnswers(shuffleArray(result).map(({i, a}) => ({img: i, correct: item.a === a})));
        setCurrent(item);
    }

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.a, arr);
            }
            process(temp, data);
        }
    }

    const setGameData = (data) => {
        setQuestions(data);
        setData(data);
        process(data, data);
    };

    const correct = () => {
        setProgressSteps(progressSteps + 1);
        setTimeout(() => {
            let temp = removeItemFromArr(current.a, questions);
            setQuestions(temp);
            next(temp);
        }, 500);
    }

    return (
        <Layout
            gameId={76667}
            currentStep={progressSteps}
            gameTitle={current.a}
            remainingLives={lives}
            reqGameData={setGameData}
            next={() => next(questions, true)}
        >
            <div className={'match-images-main'}>
                <ImagesGroup
                    imageList={answers}
                    onCorrect={correct}
                    onWrong={() => setLives(lives -1)}
                />
            </div>
        </Layout>
    )
}

export default MatchImages;