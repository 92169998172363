import React, {useEffect, useState, createContext} from "react";
import {useResizeDetector} from "react-resize-detector";

export const DataContext = createContext();

export const Provider = ({children}) => {
    const [deviceWidth, setDeviceWidth] = useState(null);
    const [deviceHeight, setDeviceHeight] = useState(null);
    const { height, width, ref } = useResizeDetector();

    useEffect(() => {
        setDeviceHeight(height)
        setDeviceWidth(width)
    }, [height, width]);

    return (
        <DataContext.Provider
            value={{
                deviceWidth,
                deviceHeight
            }}
        >
            <div ref={ref} style={{width: '100%', height: '100%'}}>
                {children}
            </div>
        </DataContext.Provider>
    );
};