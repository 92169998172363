import React from "react";
import {animated, useSpring} from "react-spring";
import './nextButton.scss';

import Next from "./flecha.png";

const defaultStyle = {
    scale: 1,
    //boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
    opacity: 1
};

const NextButton = ({onClick, className = ''}) => {
    const [{scale, boxShadow, opacity}, api] = useSpring(() => (defaultStyle));

    return <div
        className={`next-btn-wrapper ${className}`}
    >
        <animated.div
            onMouseEnter={() => api.start({
                scale: 1.1,
                //boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 1)',
                opacity: 1
            })}
            onMouseLeave={() => api.start(defaultStyle)}
            className={'next-button'}
            style={{scale, boxShadow, opacity}}
            onClick={onClick}
        >
            <animated.img src={Next} alt={'close'} width={25} style={{opacity}}/>
        </animated.div>
    </div>
};

export default NextButton;