import React, {useState} from "react";
import './selectAnswers.scss';

import Layout from "../Layout";
import Button from "../../components/Button";
import {shuffleArray} from "../../utils/helper";

const SelectAnswers = (props) => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [dataSet, setDataSet] = useState([]);

    const correction = (index) => {
        let temp = [...dataSet];

        if (temp[index].correct) {
            if (!temp[index].corrected) {
                temp[index].corrected = true;
                setDataSet(temp);
                setProgressSteps(progressSteps + 1);
            }
        } else {
            temp[index].wrong = true;
            setDataSet(temp);
            setLives(lives - 1);
        }
    }

    const setGameData = (data) => {
        let temp = [];
        const {corrects, incorrects} = data;

        corrects.forEach(value => {
            temp.push({
                value: value,
                correct: true,
                wrong: false,
                corrected: false
            })
        });

        incorrects.forEach(value => {
            temp.push({
                value: value,
                correct: false,
                wrong: false,
                corrected: false
            })
        });

        setDataSet(shuffleArray(temp));
    };

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={setGameData}
        >
            <div className={'select-answers-main'}>
                <div className={'container'}>
                    {
                        dataSet.map(({value, correct, wrong, corrected}, i) =>
                            <Button
                                className={'button-main--bigger'}
                                key={i}
                                value={value}
                                wrong={wrong}
                                onClick={() => correction(i)}
                                correct={corrected}
                                stayCorrect
                                style={{
                                    minWidth: 150,
                                    height: 40
                                }}
                            />
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}

export default SelectAnswers;