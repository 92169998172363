import React, {useState} from "react";
import './gameTestText.scss';

import Layout from "../Layout";
import {randomNum, removeItemFromArr} from "../../utils/helper";
import NextButton from "../../components/NextButton";
import GameText from "../../components/GameText";

const GameTextTest = (props) => {
    const [questions, setQuestions] = useState([]);
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [current, setCurrent] = useState({});

    const checkCorrect = (correct) => {
        if (correct) {
            setProgressSteps(progressSteps + 1)
            setTimeout(() => {
                let temp = removeItemFromArr(current.q, questions, 'q');
                setQuestions(temp);
                next(temp);
            }, 500);
        } else {
            setLives(lives - 1);
        }
    }

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.q, arr, 'q');
            }
            setCurrent(temp[randomNum(0, temp.length - 1)]);
        }
    };

    const setGameData = (data) => {
        setQuestions(data);
        setCurrent(data[randomNum(0, data.length - 1)]);
    };

    return (
        <Layout
            gameId={props.location.state.id}
            remainingLives={lives}
            currentStep={progressSteps}
            reqGameData={setGameData}
        >
            <div className={'game-test-text'}>
                <div className={'tittle'}>
                    <div>
                        <h1>{current.q}</h1>
                        <NextButton className={'btn'} onClick={() => next(questions, true)} />
                    </div>
                </div>
                <GameText
                    current={current}
                    check={checkCorrect}
                    className={current.a && current.a.length < 4 ? 'less-than-four' : ''}
                />
            </div>
        </Layout>
    )
}

export default GameTextTest;