import React, {useContext, useState} from "react";
import {useSpring, animated} from "react-spring";
import './map.scss';

import Layout from "../Layout";
import {useResizeDetector} from "react-resize-detector";
import {DataContext} from "../../utils/context";
import Button from "../../components/Button";
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";

import Wrong from './noun_Close_3490115.png';
import Correct from './noun_Check_3039054.png';
import Pointing from './flecha_puntero_100x100.gif';
import NextButton from "../../components/NextButton";

const Point = ({x, y, width, correct, wrong, onClick}) => {
    const [{scale}, api] = useSpring(() => ({
        scale: 1
    }));

    if (!width) {
        return null
    }

    return <animated.div
        style={{top: y * width, left: x * width, scale}}
        onMouseEnter={() => api.start({
            scale: 1.1,
        })}
        onMouseLeave={() => api.start({scale: 1})}
    >
        {
            (correct || wrong) ?
                <div className={correct ? 'correct' : 'wrong'} >
                    <img src={correct ? Correct : Wrong} alt={'correct'}/>
                </div>
                :
                <div onClick={() => !correct && onClick()}>
                    <div/>
                </div>

        }
    </animated.div>
}

const SinglePoint = ({x, y, width}) => {
    if (!width) {
        return null
    }

    return <div
        style={{top: (y * width) - 10, left: (x * width) - 10}}
        className={'single-point'}>
        <div>
            <img src={Pointing} alt={'pointing'} />
        </div>
    </div>
}

const IsMobile = (deviceWidth, deviceHeight) => (deviceWidth < 481) || (deviceWidth < 826 && deviceHeight < 481)

const MapRender = ({image, current, questions, onClick}) => {
    const { width, ref} = useResizeDetector();
    const {deviceWidth, deviceHeight} = useContext(DataContext);

    return <div ref={ref}>
        <img
            src={image}
            alt={'map'}
        />
        {
            IsMobile(deviceWidth, deviceHeight) ?
                <SinglePoint
                    x={current.x}
                    y={current.y}
                    width={width && width / 640}
                />
                :
                questions.map(({x, y, a, correct, wrong}, i) =>
                    <Point
                        key={i}
                        correct={correct}
                        wrong={wrong}
                        x={x}
                        y={y}
                        width={width && width / 640}
                        onClick={() => onClick(a)}
                    />)
        }
    </div>
}

const Map = () => {
    const [questions, setQuestions] = useState([]);
    const [image, setImage] = useState(null);
    const [current, setCurrent] = useState({});
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [options, setOptions] = useState([]);
    const {deviceWidth, deviceHeight} = useContext(DataContext);

    const setGameData = (data) => {
        const {background, questions} = data;
        let temp = questions;
        let item = questions[randomNum(0, questions.length - 1)];
        let opt = [];
        temp = removeItemFromArr(item.a, temp);
        opt.push(item);
        let item2 = temp[randomNum(0, temp.length - 1)];
        temp = removeItemFromArr(item2.a, temp);
        opt.push(item2);
        opt.push(temp[randomNum(0, temp.length - 1)]);
        setImage(background);
        setQuestions(questions);
        setCurrent(item);
        setOptions(shuffleArray(opt));
    }

    const pointClick = (answer) => {
        let temp = [...questions];
        let index = temp.findIndex(({a}) => a === answer);
        if (answer === current.a) {
            temp[index].correct = true;
            setQuestions(temp);
            setProgressSteps(progressSteps + 1);
            setTimeout(() => {
                next();
            }, 500);
        } else {
            temp[index].wrong = true;
            setQuestions(temp);
            setLives(lives - 1);
            setTimeout(() => {
                let temp = [...questions];
                let index = temp.findIndex(({a}) => a === answer);
                temp[index].wrong = false;
                setQuestions(temp);
            }, 500);
        }
    }

    const next = () => {
        let items = [];
        let temp = removeItemFromArr(current.a, questions);
        let index = shuffleArray(temp).find(({correct}) => !correct);
        items.push(index);
        let others = removeItemFromArr(index.a, questions);
        let item2 = others[randomNum(0, others.length - 1)];
        items.push(item2);
        others = removeItemFromArr(item2.a, others);
        items.push(others[randomNum(0, others.length - 1)]);
        setCurrent(index);
        setOptions(shuffleArray(items));
    };

    if (!deviceWidth) {
        return null
    }

    return (
        <Layout
            gameId={76749}
            currentStep={progressSteps}
            gameTitle={!IsMobile(deviceWidth, deviceHeight) && current.a}
            remainingLives={lives}
            allData={setGameData}
            next={next}
        >
            <div className={'map-container-main'}>
                {
                    IsMobile(deviceWidth, deviceHeight) &&
                    <NextButton
                        className={'next-btn'}
                        onClick={next}
                    />
                }
                <div className={'img-container'}>
                    <MapRender
                        image={image}
                        current={current}
                        questions={questions}
                        onClick={pointClick}
                    />
                </div>
                <div className={'button-container'}>
                    {
                        options.map((item, i) => <Button
                            key={i}
                            value={item.a}
                            wrong={item.a !== current.a}
                            onClick={() => pointClick(item.a)}
                            style={{minWidth: 70}}
                            correct={item.a === current.a}
                        />)
                    }

                </div>
            </div>
        </Layout>
    )
}

export default Map;