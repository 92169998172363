import React, {useState} from "react";
import './identifyImage.scss';

import Layout from "../Layout";
import KeyBoardButtons from "../../components/KeyBoardButtons";
import ImageFrame from "../../components/ImageFrame";

import PointScored from "../../components/PonitScored/PointScored";
import {randomNum, removeItemFromArr, shuffleArray} from "../../utils/helper";

const IdentifyImage = (props) => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives, setLives] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [options, setOptions] = useState([]);
    const [spaces, setSpaces] = useState([]);
    const [current, setCurrent] = useState({});

    const processed = (data) => {
        let item = data[randomNum(0, data.length - 1)];
        let answer = item.a.trim().split('');
        setSpaces(answer.map(item => item === ' ' ? 'space' : ''));
        setOptions(shuffleArray(
            answer.filter(item => item !== ' ')
                .map(char => char.toUpperCase())
                .filter((item, pos, self) => (self.indexOf(item) === pos))
        ));
        setCurrent(item);
    }


    const gameData = (data) => {
        setQuestions(data);
        processed(data);
    };

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.a, arr);
                processed(temp);
            } else {
                setTimeout(() => {
                    processed(temp);
                }, 400);
            }
        }
    };

    const correctTry = () => {
        setProgressSteps(progressSteps + 1)
        let temp = removeItemFromArr(current.a, questions);
        setQuestions(temp);
        next(temp);
    }

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={gameData}
        >
            <PointScored score={progressSteps}/>
            <div className={'identify-image-main'}>
                <div className={'image-wrapper'}>
                    <ImageFrame onClick={() => next(questions, true)} source={current.i}/>
                </div>
                <div className={'key-board'}>
                    <KeyBoardButtons
                        answerSlots={spaces}
                        answerOpts={options}
                        correctAnswer={current.a}
                        incorrectTry={() => setLives(lives - 1)}
                        goToNextGame={correctTry}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default IdentifyImage;