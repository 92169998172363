import React, {useState} from "react";
import './carousel.scss';

import Layout from "../Layout";
import Button from "../../components/Button";
import {randomNum, removeItemFromArr} from "../../utils/helper";

const Carousel = (props) => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [current, setCurrent] = useState({});
    const [lives, setLives] = useState(0);
    const [progressSteps, setProgressSteps] = useState(0);
    const [corrected, setCorrected] = useState(false);

    const next = (arr = questions, skip = false) => {
        if (arr.length) {
            let temp = arr;
            if (skip) {
                temp = removeItemFromArr(current.a, arr);
            }
            setCurrent(temp[randomNum(0, temp.length - 1)]);
        }
    };

    const correction = (item) => {
        setCorrected(true);
        setTimeout(() => {
            if (item === current.a) {
                let temp = removeItemFromArr(current.a, questions);
                setProgressSteps(progressSteps + 1)
                setQuestions(temp);
                next(temp);
            } else {
                setLives(lives - 1);
            }
            setCorrected(null);
        }, 500);
    }

    const setGameData = (data) => {
        console.log(props)
        setQuestions(data);
        setAnswers(data);
        setCurrent(data[randomNum(0, data.length - 1)]);
    }

    return (
        <Layout
            gameId={props.location.state.id}
            currentStep={progressSteps}
            gameTitle={current.q}
            remainingLives={lives}
            next={() => next(questions, true)}
            reqGameData={setGameData}
        >
            <div className={'carousel-main'}>
                <div className={'container'}>
                    {
                        answers.map(({a}, i) =>
                            <Button
                                className={'button-main--bigger'}
                                key={i}
                                value={a}
                                onClick={() => correction(a)}
                                correct={corrected && a === current.a}
                                style={{
                                    minWidth: 180,
                                    height: 50
                                }}
                                stayCorrect
                                animation
                            />
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Carousel;