import React, {useEffect, useState} from "react";
import "./whiteList.scss";

import Layout from "../Layout";

const Item = ({input, value, setValue}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (input && input.toLowerCase() === value.toLowerCase()) {
            setVisible(true);
            setValue();
        }
    }, [input, value, visible, setValue])

    return (
        <div className={'list-item'}>
            {!visible ? <div className={'check'}>&#9711;</div> : <div className={'check'}>&#x2713;</div>}
            <p>{visible && value}</p>
        </div>

    )
}
const WhiteList = (props) => {
    const [progressSteps, setProgressSteps] = useState(0);
    const [lives] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [value, setValue] = useState("");

    const correct = () => {
        setValue('');
        setProgressSteps(progressSteps + 1);
    }
    
    useEffect(() => {
        setQuestions(questions.concat(questions))
    },[])
    return (
        <Layout
            gameId={51977}
            currentStep={progressSteps}
            remainingLives={lives}
            reqGameData={setQuestions}
        >
            <div className={'white-content'}>
                <div className="input">
                    <input value={value} onChange={evt => setValue(evt.target.value)}
                           placeholder="Escribe tus opciones"/>
                </div>
                <div className={'list'}>
                    <div className={'list-wrapper'}>
                        <div/>
                        <div className={'list-item blank'} />
                        {questions.map((item, i) =>
                            <Item
                                key={i}
                                value={item}
                                setValue={correct}
                                input={value}
                            />
                        )}
                        <div className={'list-item blank'} />
                        <div className={'blank-pages'}>
                            <div/>
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default WhiteList;
