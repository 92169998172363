export const shuffleArray = (array) => {
    let a = [...array];
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const randomNum = (min, max) => {
    return Math.floor((Math.random() * (max - min + 1)) + min);
};

export const removeItemFromArr = (answer, arr, parm = false) => {
    const attribute = parm || 'a';

    let temp = [...arr];
    let index = temp.findIndex(item => item[attribute] === answer);
    temp.splice(index,1);

    return temp;
};