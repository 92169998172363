import React from "react";
import './stepCounter.scss';

const StepCounter = ({current, steps}) => {

    return (
        <div className={'step-counter-main'}>
            {current}/{steps}
        </div>
    )
}

export default StepCounter;